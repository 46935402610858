import { Box, Text } from "@chakra-ui/core"
import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import Card from "../components/YuGiOh/Card/Card"
import Header from "../components/YuGiOh/Header/Header"
import "./index.scss"

const SpellPage = () => {
  const [level, setLevel] = useState(12)
  const [atk, setAtk] = useState(0)
  const [cardId, setCardId] = useState(null)
  const [cardName, setCardName] = useState("")
  const logo = require("../images/bg.png")

  return (
    <Layout>
      <Box
        position="fixed"
        zIndex={0}
        top={0}
        left={0}
        height="100vh"
        width="100vw"
        bgImage={`url("${logo}")`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      />
      <Header
        setLevel={setLevel}
        level={level}
        atk={atk}
        setAtk={setAtk}
        spellCard={true}
        trapCard={false}
        monster={false}
        setName={setCardName}
      />
      <Card
        level={level}
        atk={atk}
        monster={false}
        trapCard={false}
        cardId={cardId}
        setCardId={setCardId}
        cardName={cardName}
      />
      <Box position="relative" textAlign="center" mt={10}>
        <a
          href="https://www.instagram.com/0xpierre.dev/"
          target="_blank"
          rel="noreferrer"
        >
          <Text color="white" fontSize={15} fontWeight={600}>
            @0xpierre.dev - {new Date().getFullYear()}
          </Text>
        </a>
      </Box>
    </Layout>
  )
}

export default SpellPage
